import './Header.scss';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CodeIcon from '@mui/icons-material/Code';
import { Link } from 'react-router-dom';
function Header() {
  const linkedinUrl = 'https://www.linkedin.com/in/sharao';
  return (
    <header className='header'>
      <div className='nav-bar'>
        <div className='header__logo'>Srinivas Rao Gunja</div>

        <div className='contact  '>
          <Stack direction='row' spacing={2}>
            <Link to='/code'>
              <Button variant='contained' endIcon={<CodeIcon />}>
                Code
              </Button>
            </Link>
            <a href={linkedinUrl} target='_blank' rel='noopener noreferrer'>
              <Button variant='contained' endIcon={<LinkedInIcon />}>
                &nbsp;
              </Button>
            </a>
          </Stack>
        </div>
      </div>
    </header>
  );
}

export default Header;
