import React from 'react';
import styles from './Code.module.scss';

const Code = () => {
  return (
    <div className={styles.parentContainer}>
      <div className={styles.child}>Child 1</div>
      <div className={styles.child}>Child 2</div>
      <div className={styles.child}>Child 3</div>
    </div>
  );
};

export default Code;
