import './Skills.scss';
import Button from '@mui/material/Button';
import VerifiedIcon from '@mui/icons-material/Verified';

interface ItemProps {
    text: string;
}

const Item: React.FC<ItemProps> = ({ text }) => {
    return (<Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<VerifiedIcon />}
        color='success'

        style={{
            borderRadius: 35,
            background: 'var(--primary-color-alpha)',
            padding: "18px 36px",
            fontSize: "18px",
            margin: '1rem 1rem 0 0'
        }}
        className='skill'
    >
        {text}

    </Button>);
};

// And you have an array of items
const itemsArray = [
    { id: 1, text: 'JavaScript' },
    { id: 2, text: 'ReactJS' },
    { id: 3, text: 'TypeScript' },
    { id: 4, text: 'SASS' },
    { id: 5, text: 'CSS' },
    { id: 6, text: 'HTML' },
    { id: 7, text: 'Responsive Design' },
    { id: 8, text: 'Page Speed Optimization' },
    { id: 9, text: 'Mobile Optimization' },
    { id: 10, text: 'Lazy Loading' },
    { id: 11, text: 'Version Control Systems (Git)' },
    { id: 12, text: 'Build Tools (Webpack)' },
    { id: 13, text: 'Testing Frameworks(JEST, Jasmine, Karma)' },
    { id: 14, text: 'RESTful APIs and Data Fetching' },
];
const softArray = [
    { id: 1, text: 'Problem-solving and Critical Thinking' },
    { id: 2, text: 'Communication Skills' },
    { id: 3, text: 'Teamwork' },
    { id: 4, text: 'Independent Learning' },

];

export default function Skills() {
    return (
        <div className='skills-container'>
            <h1>Primary Skills</h1>
            {itemsArray.map(item => (
                <Item key={item.id} text={item.text} />
            ))}

            <h2 className='h1'>Soft Skills</h2>
            {softArray.map(item => (
                <Item key={item.id} text={item.text} />
            ))}

        </div>
    );
}