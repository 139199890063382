import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.scss';

import Header from './components/Header';
import Portfolio from './components/Portfolio';

import Code from './components/code/Code';

function App() {
  return (
    <div className='App'>
      <Header />
      <section>
        <div className='app-body'>
          <Routes>
            <Route path='/' element={<Portfolio />} />
            <Route path='/Code' element={<Code />} />
            {/* <Route path='/contact' element={<Contact />} /> */}
          </Routes>
        </div>
      </section>
    </div>
  );
}

export default App;
