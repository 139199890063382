import './Portfolio.scss';
import Skills from './Skills';
function Portfolio() {
    return (
        <div className='portfolio'>
            <h1>Frontend Developer</h1>
            <h2>I craft user-friendly interfaces that bring complex ideas to life.  As a Frontend Developer, I love the challenge of translating beautiful designs into clean, functional code.</h2>
            <Skills />
        </div>
    )
}

export default Portfolio